import React from 'react';
import { graphql } from 'gatsby';
import { Section } from 'react-komponents';
import { Flex, Stack, Heading } from '@chakra-ui/core';

import { PageLayout } from '../components/Layouts/layout';
import withI18next from '../i18n';
import SEO from '../components/seo';
import { ArticleList } from '../components/ArticleList/articleList.component';

const BlogPage = ({ location, data: { articles }, pageContext }) => {
  return (
    <PageLayout lang={pageContext.langKey} location={location}>
      <SEO
        title="Blog"
        description="Nos articles de blog"
        location={location}
        lang={pageContext.langKey}
      />
      <Section charlie>
        <Flex align="center" direction="column" mt={60}>
          <Stack maxWidth={800} width="100%">
            <Heading as="h1" fontSize="5xl" textAlign="center">
              Bienvenue sur notre blog
            </Heading>
            <ArticleList lang={pageContext.langKey} articles={articles.edges} />
          </Stack>
        </Flex>
      </Section>
    </PageLayout>
  );
};

export const pageQuery = graphql`
  query allArticlesBlog {
    articles: allMdx(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { template: { eq: "post" } } }
    ) {
      edges {
        node {
          fields {
            slug
            path
          }
          excerpt
          timeToRead
          frontmatter {
            title
            categories
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            date
            template
          }
        }
      }
    }
  }
`;

export default withI18next(BlogPage);
